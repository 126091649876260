import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { UitkHeading, UitkHeadingSize, UitkHeadingSizes } from "@egds/react-core/text";
import { TitleContainerProps } from "./typings";
import { UitkSpacing, UitkSpacingProps } from "@egds/react-core/spacing";

const defaultSize = 3;

export const TitleContainer = withStores("context")(
  observer((props: TitleContainerProps) => {
    const { templateComponent } = props;

    /* istanbul ignore if */
    if (!templateComponent) {
      return null;
    }

    const {
      config: { hasPaddingBottom, hasInlinePadding, hasPaddingTop, title, headingSize },
    } = templateComponent;

    /* istanbul ignore next */
    if (!title) {
      return null;
    }

    /* istanbul ignore next */
    const getTitlePadding = (): UitkSpacingProps["padding"] => {
      return {
        blockend: hasPaddingBottom ? "three" : "unset",
        inline: hasInlinePadding ? "three" : "unset",
        blockstart: hasPaddingTop ? "three" : "unset",
      };
    };

    const uitkHeadingSize =
      headingSize && UitkHeadingSizes.has(headingSize as UitkHeadingSize) ? headingSize : defaultSize;

    return (
      <UitkSpacing padding={getTitlePadding()}>
        <UitkHeading tag="h2" size={uitkHeadingSize as UitkHeadingSize}>
          {title}
        </UitkHeading>
      </UitkSpacing>
    );
  })
);

export default TitleContainer;
